"use client"; // Assurez-vous que ce fichier est un composant client

import React, { useEffect } from 'react';
import Modal from 'react-modal';

const ClientModalInitializer = () => {
    useEffect(() => {
        const appElement = document.querySelector('#__next');
        if (appElement && appElement instanceof HTMLElement) {
            console.log("Element found:", appElement); // Log pour déboguer
            Modal.setAppElement(appElement);
        } else {
            console.log("Element not found, retrying..."); // Log pour déboguer
            setTimeout(() => {
                const retryElement = document.querySelector('#__next');
                if (retryElement && retryElement instanceof HTMLElement) {
                    Modal.setAppElement(retryElement);
                }
            }, 100); // Réessayer après 100 ms
        }
    }, []);

    return null; // Ce composant n'affiche rien
};

export default ClientModalInitializer;


