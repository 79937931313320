import { PeopleCardProps } from "@/components/ui/PeopleCard";
import { PricingCardProps } from "@/components/ui/PricingCard";
import { ReviewCardProps } from "@/components/ui/ReviewCard";
import XSVG from "@/components/ui/X";
import { Instagram, Linkedin, Mic, Youtube } from "lucide-react";

interface Config {
  siteTitle: string;
  MenuLinks: Array<{
    href: string;
    label: string;
    langKey: string;
  }>;
  fondator: PeopleCardProps;
  comedians: PeopleCardProps[];
  pricingCard: PricingCardProps[];
  reviews: ReviewCardProps[];
}

const config: Config = {
  siteTitle: "S.A.C Studio",
  MenuLinks: [
    { href: "/#hero", label: "Accueil", langKey: "home" },
    { href: "/#combat", label: "Notre combat", langKey: "fight" },
    { href: "https://www.sacstudio.be/comedien", label: "Nos Comédiens", langKey: "team" },
    { href: "/#tarifs", label: "Nos tarifs", langKey: "rate" },
    { href: "/#contact", label: "Contact", langKey: "contact" },
  ],
  fondator: {
    nomPrenom: "Ricardo Rhodes (Akira)",
    posteFr: "Fondateur",
    posteEn: "Founder",
    image: "ricardo",
    flag: ["fr", "en"],
    audios: {
      fr: [],
      en: [],
      jp:[],
    },
    iconsFr: [
      {
        icon: <Instagram width={20} height={20} />,
        lien: "https://www.instagram.com/sac.studio.officiel/",
      },
      {
        icon: <Linkedin width={20} height={20} />,
        lien: "https://linkedin.com",
      },
    ],
  },
  comedians: [
    {
      nomPrenom: "Chloé François (Tolémet)",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "chloe",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/chloe/Audiobook-100-Jours.mp3", title: "Elisa M.Poggio - 100 Jours De Nuit - Livre Audio (Audible)" },
          { src:"../audios/chloe/GFP-Enfant.mp3", title: "GFP (Enfant) - Opération K-K-WET - Fiction Audio" },
          { src:"../audios/chloe/Gus-Parmi-Eux.mp3", title: "Gus - Parmi Eux - Fiction Audio" },
          { src:"../audios/chloe/Demo-IVR-S.A.C-Studio.mp3", title: "IVR / SVI - S.A.C Studio" },
          { src:"../audios/chloe/Sacha-Vanille.mp3",title: "Sacha Vanille - Sascha - Fiction Audio" },
          { src:"../audios/chloe/Tommy-Pommette.mp3",title: "Tommy Pommette - TRAUMA FANTASY X  - Fiction Audio" },
          { src:"../audios/chloe/Miranda-Monster-Prom.mp3",title: "Miranda - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },
          { src:"../audios/chloe/Valérie-Monster-Prom.mp3", title: "Valérie - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },
          { src:"../audios/chloe/Joy-Monster-Prom.mp3",title: "Joy - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      nomPrenom: "Mathieu Moreau",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "mathieu",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/mathieu/Zabuza_Momochi.mp3", title: "Zabuza Momochi - Naruto - Animé" },
          { src:"../audios/mathieu/Baggy.mp3", title: "Baggy Le Clown - One Piece - Animé" },
          { src:"../audios/mathieu/Kanan-Star-Wars-Rebels-Saison1-Séries-danimation.mp3",title: "Kanan - Star Wars Rebels Saison 1 - Séries d'animation" },
          { src:"../audios/mathieu/Albator.mp3", title: "Albator Corsaire de l'espace - Film d'animation" },
          { src:"../audios/mathieu/Leon_Kennedy.mp3",title: "Leon S. Kennedy - Resident Evil Degeneration - Film d'animation" },
          { src:"../audios/mathieu/Qui.mp3",title: "Qui peut sauver le Far West ? - Abahachi Winnetouch - Film" },
          { src:"../audios/mathieu/Pokémon.mp3",title: "Pokémon - Bande-annonce animée (Nintendo DS)" },
          { src:"../audios/mathieu/Yamikai.mp3", title: "Yamikai - Le Monde Obscur - Bande Annonce Manga" },
          { src:"../audios/mathieu/Maraad.mp3",title: "Maraad - World of Warcraft Warlords of Draenor - Jeux vidéo" },
          { src:"../audios/mathieu/Le_directeur_Salges_Épisode_1.mp3", title: "Le directeur Salges - Fond de Cale - Épisode 1 - Fiction Audio (2023)" },
          { src:"../audios/mathieu/Le_directeur_Salges_Épisode_2.mp3",title: "Le directeur Salges - Fond de Cale - Épisode 2 - Fiction Audio (2024)" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      nomPrenom: "Eriko Hayami",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "eriko",
      flag: ["jp"],
      audios: {
        jp: [
          { src:"../audios/eriko/–‚Ź—.wav", title: "–‚Ź—" },
          { src:"../audios/eriko/Aoudiobok_昔話.wav", title: "Aoudiobok 昔話" },
          { src:"../audios/eriko/e-learning心理学.wav", title: "e-learning 心理学" },
          { src:"../audios/eriko/IVRƒ{ƒCƒXƒfƒ.wav", title: "IVR" },
          { src:"../audios/eriko/エルフ.wav", title: "エルフ" },
          { src:"../audios/eriko/キュートな妖精.wav", title: "キュートな妖精" },
          { src:"../audios/eriko/シルバーラップトップ_YouTube広告.mp3", title: "シルバーラップトップ_YouTube広告" },
          { src:"../audios/eriko/ドキュメンタリー.mp3", title: "ドキュメンタリー" },
          { src:"../audios/eriko/不動産会社ウェブ広告.wav", title: "不動産会社ウェブ広告" },
          { src:"../audios/eriko/企業紹介.wav", title: "企業紹介" },
        ],
        fr : [

        ],
        en: [
        ],
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Alex Morrison (Neckhaos)",
      posteFr: "Compositeur & Comédien",
      posteEn: "Screenwriter & Actor",
      image: "alex",
      flag: ["fr", "en"],
      audios: {
        fr: [
          { src:"../audios/alex/Archiford_Clyde_Vanilla_EP4.mp3", title: "Archiford - Clyde Vanilla - Épioosde 4 - Fiction Audio" },
          { src:"../audios/alex/Disco_Jesus_Clyde_Vanilla_EP7.mp3", title: "Disco Jesus - Clyde Vanilla - Épioosde 7 - Fiction Audio" },
          { src:"../audios/alex/Fitzgerald_S3_EP4_Dahut.mp3", title: "Dabut - Les Contes de Fitzgerald - S3 Épiosde 4 - Fiction Audio" },
          { src:"../audios/alex/Pompiste_Les_Pourfendeurs_EP5.mp3", title: "Pompiste - Les Pourfendeurs - Épisode 5 - FIction Audio" },
          { src:"../audios/alex/Pompiste_2_Pourfendeurs_EP10.mp3", title: "Pompiste - Les Pourfendeurs - Épisode 10 - FIction Audio" },
          { src:"../audios/alex/Présentateur_EP1_TFX.mp3", title: "Présentateur - Trauma Fantasy X - Épisode 1 -  Fiction Audio" },
          { src:"../audios/alex/Raphael_Believe_in_yourself_Spot_3.mp3", title: "Raphael - Believe in yourself - Épisode 3 - Fiction Audio" },
          { src:"../audios/alex/Spot_Pub_pourfendeur.mp3", title: "Faux Spot Pub - Les Pourfendeurs - Fiction Audio" },
          { src:"../audios/alex/Stauron_TFX_EP2.mp3", title: "Stauron - Trauma Fantasy X - Épisode 2 -  Fiction Audio" },
          { src:"../audios/alex/PapÇ_Clyde_Vanilla_Ep6.mp3", title: "Le Papé - Clyde Vanilla - Épisode 6 - Fiction Audio" },
          { src:"../audios/alex/Glagnork_EP19_Nick.mp3", title: "Nick - Le Monde Pitoyable De Glagnork - Épisode 19 - Fiction Audio" },

        ],
        en: [
          { src:"../audios/alex/Ricardo-Rhodes-S.A.C-Studio-Video-presentation.mp3", title: "Ricardo Rhodes - S.A.C studio - Video presentation"},
          { src:"../audios/alex/IVR-_-SVI-S.A.C-Studio-_EN_.mp3", title: "IVR / SVI - S.A.C Studio"},
          { src:"../audios/alex/Jim_&_Michael_Glagnork_EP21.mp3", title: "Jim & Michael - Le Monde Pitoyable De Glagnork - Épisode 21 - Fiction Audio" },
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Eva Aleman",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "eva",
      flag: ["fr","en", "es"],
      audios: {
        fr: [
          { src:"../audios/eva/Eva-Aleman_La_Boîte_e-book_Français.mp3", title: "La Boîte e-book" },
          { src:"../audios/eva/Eva-Aleman_Louvain-Cooperation_Corporate_Français.mp3", title: "Louvain Cooperation Corporate" },
          { src:"../audios/eva/Eva-Aleman_Publicité_Radio_Français.wav", title: "Publicité Radio" },
          { src:"../audios/eva/Eva-Aleman_Publicité_TV_Français.mp3", title: "Publicité TV" },
          { src:"../audios/eva/Eva-Aleman-Pink_Gellac_UGC_Français.wav", title: "Pink Gellac UGC" },
        ],
        en: [
          { src:"../audios/eva/Eva-Aleman_Cockerill_Corporate_Anglais.mp3", title: "Cockerill Corporate" },
          { src:"../audios/eva/Eva-Aleman_European_Parliament_Corporate_Anglais.mp3", title: "European Parliament Corporate" },
          { src:"../audios/eva/Eva-Aleman_Samsonite_Publicité_online_Anglais.mp3", title: "Samsonite Publicité online" },
        ],
        es: [
          { src:"../audios/eva/Eva_Aleman_Frenehard_Michaux_Corporate_Espagnol.mp3", title: "Frenehard Michaux Corporate" },
          { src:"../audios/eva/Eva-Aleman_Fantastic_Brussels_Audio_Musee_Espagnol.mp3", title: "Fantastic Brussels Audio Musee" },
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Alain Chaillot",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "alain",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/alain/AssuranceVie-Meilleurtaux-PubTV.mp3", title: "Assurance Vie - Meilleur taux - Pub TV" },
          { src:"../audios/alain/ChampionsLeague2024-Pubradio.mp3", title: "Champions League 2024 - Pub radio" },
          { src:"../audios/alain/GuadeloupeBillboard-VSMradio.mp3", title: "Guadeloupe Billboard - VSM radio" },
          { src:"../audios/alain/JamesRosone-Auxconfinsdesétoiles-Livreaudio(Audible).mp3", title: "James Rosone - Aux confins des étoiles - Livre audio (Audible)" },
          { src:"../audios/alain/GuillaumeLecointre.mp3", title: "Guillaume Lecointre - L'évolution question d'actualité ? - Livre audio (Audible)" },
          { src:"../audios/alain/JEEP-Pubradio.mp3", title: "JEEP - Pub radio" },
          { src:"../audios/alain/MédiaClinic-Pubradio.mp3", title: "Média Clinic - Pub radio" },
          { src:"../audios/alain/SOPRANO2025-Pubradio.mp3", title: "SOPRANO 2025 - Pub radio" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Véronique Fyon",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "veronique",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/veronique/Bizo-LeoRoidelaJungle-Filmdanimation.mp3", title: "Bizo - Léo Roi de la Jungle - Film d'animation" },
          { src:"../audios/veronique/Fin-adventureTime-Seriesdanimation(chant).mp3", title: "Fin - adventure Time - Séries d'animation (chant)" },
          { src:"../audios/veronique/Fin-adventureTime-Seriesdanimation.mp3", title: "Fin - adventure Time - Séries d'animation" },
          { src:"../audios/veronique/Manu-Cedric-Seriesdanimation.mp3", title: "Manu - Cédric - Séries d'animation" },
          { src:"../audios/veronique/RikaNoyamano-AirGear-Anime.mp3", title: "Rika Noyamano - Air Gear - Animé" },
          { src:"../audios/veronique/Vicky-Mesparrainssontmagiques-Seriesdanimation.mp3", title: "Vicky - Mes parrains sont magiques - Séries d'animation" },
          { src:"../audios/veronique/voixoff-pandemie.mp3", title: "voix off - pandémie" },

        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Jamal-deen (Ademola) Hamzat",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "jamal",
      flag: ["en","ng"],
      audios: {
        fr: [
        ],
        en: [
          { src:"../audios/jamal/ACEMoneyTransfer-DeliveringYourPromises-Commercials.mp3", title: "ACE Money Transfer - Delivering Your Promises - Commercials" },
          { src:"../audios/jamal/Africanaccent-GeneralDemoreel.mp3", title: "African accent - General Demo reel" },
          { src:"../audios/jamal/FederalGovernmentofNigeria-commonwealthgames-Trialer.mp3", title: "Federal Government of Nigeria - commonwealth games - Trialer" },
          { src:"../audios/jamal/PLANINTERNATIONAL-GENDERTRANSFORMATION-E-LEARNING.mp3", title: "Plan International - Gender transformation E-learning" },
          { src:"../audios/jamal/RIDA-Driverstep-by-step-Explainer.mp3", title: "RIDA - Driver step-by-step - Explainer" },
          { src:"../audios/jamal/UNDP-Animation-SeriesEnergy-Narration.mp3", title: "UNDP - Animation - Series Energy - Narration" },
          { src:"../audios/jamal/Supersport-Sportybet-Promo.mp3", title: "Supersport - Sportybet - Promo" },
          { src:"../audios/jamal/Yango-Radio-Commercial.mp3", title: "Yango - Radio - Commercial" },
          { src:"../audios/jamal/ZUWUTU-OFFICEPROMPT-IVR.mp3", title: "ZUWUTU - OFFICE PROMPT - IVR" },
        ],
        ng:[],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Cécile Heredia",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "cecile",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/cecile/BillboardTV-Milka-Publicit.mp3", title: "Billboard TV - Milka - Publicité" },
          { src:"../audios/cecile/Explainer-Showreel-e-learning.mp3", title: "Explainer - Showreel - E-learning" },
          { src:"../audios/cecile/Felicia-PAPERPORT-dessinanime.mp3", title: "Felicia - PAPER PORT - Dessin animé" },
          { src:"../audios/cecile/Narration-documentairescientifique.mp3", title: "Narration - Documentaire scientifique" },
          { src:"../audios/cecile/Narratrice-Manouvellevielaplage-Documentaire.mp3", title: "Narratrice - Ma nouvelle vie la plage - Documentaire" },
          { src:"../audios/cecile/Personnages-showreel-voixcartoon.mp3", title: "Personnages - showreel - Voix cartoon" },
          { src:"../audios/cecile/Publicite-showreel-diffusionmagasin.mp3", title: "Publicité - showreel - Diffusion magasin" },
          { src:"../audios/cecile/Publicite-showreel-interpretationsjouees.mp3", title: "Publicité - showreel - Interprétations jouées" },
          { src:"../audios/cecile/Voiceover-Interpretationnaturelle.mp3", title: "Voice over - Interprétation naturelle" },
          { src:"../audios/cecile/VoixAntenne-Showreel-habillagesradio.mp3", title: "Voix Antenne - Showreel - habillages radio" },
          { src:"../audios/cecile/Voixoff-interpretationinspirationnelle.mp3", title: "Voix Off - Interprétation inspirationnelle" },
          { src:"../audios/cecile/Voixoff-InterpretationMarrantepositive.mp3", title: "Voix Off - Interprétation Marrante positive" },
          { src:"../audios/cecile/Voixoff-Interpretationcorporate.mp3", title: "Voix off - Interprétation corporate" },
          { src:"../audios/cecile/VoixOff-InterpretationDetendue.mp3", title: "Voix Off - Interprétation détendue" },
          { src:"../audios/cecile/JournaldeFrance-publiciteinstitutionnelle-interpretationenergique.mp3", title: "Journal de France - Publicité institutionnelle - Interprétation énergique" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Noboru Okamoto",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "noboru",
      flag: ["jp"],
      audios: {
        jp: [
          { src:"../audios/noboru/岡本昇 SNSバンパー広告.mp3", title: "岡本昇 SNSバンパー広告" },
          { src:"../audios/noboru/岡本昇 TV番組 女子高生ミスコン.mp3", title: "岡本昇 TV番組 女子高生ミスコン" },
          { src:"../audios/noboru/岡本昇 かけがえのない人 映画トレーラー.mp3", title: "岡本昇 かけがえのない人 映画トレーラー" },
          { src:"../audios/noboru/岡本昇 映画ゾンビスクール 映画トレーラー.mp3", title: "岡本昇 映画ゾンビスクール 映画トレーラー" },
          { src:"../audios/noboru/岡本昇 スポーツ等 煽り.mp3", title: "岡本昇 スポーツ等 煽り" },
          { src:"../audios/noboru/岡本昇 セリフ ダイジェスト.mp3", title: "岡本昇 セリフ ダイジェスト" },
          { src:"../audios/noboru/岡本昇 マニュアル.mp3", title: "岡本昇 マニュアル" },
          { src:"../audios/noboru/岡本昇 介護 親しみ、信頼、清潔.mp3", title: "岡本昇 介護 親しみ、信頼、清潔" },
          { src:"../audios/noboru/岡本昇 企業VP 低音.mp3", title: "岡本昇 企業VP 低音" },
          { src:"../audios/noboru/岡本昇 企業VP 落ち着き.mp3", title: "岡本昇 企業VP 落ち着き" },
          { src:"../audios/noboru/岡本昇 電気アプリCM 明るい訴求.mp3", title: "岡本昇 電気アプリCM 明るい訴求" },

        ],
        fr:[],
        en: [
        ]
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Arthur Pouillès-Duplaix (Wolf)",
      posteFr: "Comédien & Gestion de projet Youtube ",
      posteEn: "Actor & YouTube Project Management",
      image: "arthur",
      flag: ["fr", "en"],
      audios: {
        fr: [
          { src:"../audios/arthur/8eSortilège_Pratchett.mp3", title: "Terry Pratchett - Le Huitième Sortilège - Livre Audio" },
          { src:"../audios/arthur/DesHommesSansFemme_Murakami.mp3", title: "Haruki Murakami - Des Hommes Sans Femme - Livres Audio" },
          { src:"../audios/arthur/ArthurPouillesduplaix_IVR_Demo.mp3", title: "IVR / SVI - S.A.C Studio" },
          { src:"../audios/arthur/Florian-Sascha.mp3", title: "Florian - Sascha - Fiction Audio" },
          { src:"../audios/arthur/1287-LordMoustache.mp3", title: "Lord Moustache - 1287 - Fiction Audio" },
          { src:"../audios/arthur/Doom_Man-D_Day.mp3", title: "Doom Man - D Day - Fiction Audio" },
          { src:"../audios/arthur/LesPourfendeurs-Huggy.mp3", title: "Huggy - Les Pourfendeurs  - Fiction Audio" },
          { src:"../audios/arthur/Moignon_Robert_Groin.mp3", title: "Moignon - Robert Groin - Fiction Audio" },
          { src:"../audios/arthur/Policiers-Café-_-Lembas.mp3", title: "Café & Lembas - Policiers - Fiction Audio" },
          { src:"../audios/arthur/Narration-Forces-Mentales.mp3", title: "Narration - Forces Mentales - Fiction Audio" },
          { src:"../audios/arthur/Narration-The-Blind-Zone.mp3", title: "Narration - The Blind Zone - Fiction Audio" },
          { src:"../audios/arthur/Peter-Syriv-Jencyo-Rêva.mp3", title: "Peter Syriv - Jencyo Rêva - Fiction Audio" },
          { src:"../audios/arthur/Philippe-du-Petipu-_Les-Résidents-et-Vils-Pouilleux_-_1_.mp3", title: "Philippe du Petipu - Les Résidents et Vils Pouilleux - Fiction Audio" },
          { src:"../audios/arthur/MonsterProm_Damien.mp3", title: "Damien - Monster Prom - Let's Play Narratif (Studio Post-Météore)" },

        ],
        en: [
          { src:"../audios/arthur/IVR_English.mp3", title: "IVR / SVI - S.A.C Studio" },
        ],
        jp: []
      },
      iconsFr: [
      ],
      iconsEn: [
      ],
    },
    {
      nomPrenom: "Claire Pradalié",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "claire",
      flag: ["fr","en"],
      audios: {
        fr: [
          { src:"../audios/claire/Abeille-Chromebook-Pub.mp3", title: "Abeille - Chromebook - Pub" },
          { src:"../audios/claire/Atelier-Chocolat-Pub.mp3", title: "Atelier - Chocolat - Pub" },
          { src:"../audios/claire/Antipuces-Frontlinecombo-pub.mp3", title: "Antipuces - Frontline combo - Pub" },
          { src:"../audios/claire/BACKTOSCHOOL-HPInstantInk-Pub.mp3", title: "BACK TO SCHOOL - HP Instant Ink - Pub" },
          { src:"../audios/claire/Aureliane-Lespoirsurlerivage-Livreaudio(Audible).mp3", title: "Aureliane - L'espoir sur le rivage - Livre audio (Audible)" },
          { src:"../audios/claire/DrunkonLove-livreaudio(Audible).wav", title: "Drunk on Love - Livre audio (Audible)" },
          { src:"../audios/claire/Kassie-Liaisons.com-Livreaudio(Audible).mp3", title: "Kassie-Liaisons.com - Livre audio (Audible)" },
          { src:"../audios/claire/Doudous-Lemondemerveilleuxdesdoudous-livreaudio.mp3", title: "Doudous - Lemonde merveilleux des doudous - Livre audio" },
          { src:"../audios/claire/Anzu-Suzuran-Livreaudio.mp3", title: "Anzu - Suzuran - Livre audio" },
          { src:"../audios/claire/Eléphants-Babar-Libreaudio.mp3", title: "Eléphants - Babar - Livre audio" },
          { src:"../audios/claire/Lespoules-Lapetitepoulequivoulaitvoirelamer-Livreaudio.mp3", title: "Les poules - La petite poule qui voulait voire la mer - Livre audio" },
          { src:"../audios/claire/Lalicorne-Histoirepourdormir-méditation.mp3", title: "La licorne - Histoire pour dormir - Méditation" },
          { src:"../audios/claire/Visualisation-méditation.mp3", title: "Visualisation - Méditation" },
          { src:"../audios/claire/Carnetdebal-Docu.mp3", title: "Carnet de bal - Docu" },
          { src:"../audios/claire/Friginox-Institutionnel.mp3", title: "Friginox - Institutionnel" },
          { src:"../audios/claire/Infirmière-podcast.mp3", title: "Infirmière - Podcast" },
          { src:"../audios/claire/Méo-Spotify-Pub.mp3", title: "Méo - Spotify - Pub" },
          { src:"../audios/claire/Pokemon-jeuenfant-Pub.mp3", title: "Pokemon - jeu enfant - Pub" },
          { src:"../audios/claire/roquefort-Fromage-Pub.mp3", title: "Roquefort - fromage - Pub" },
          { src:"../audios/claire/Spectrum-Institutionnel.mp3", title: "Spectrum - Institutionnel" },
        
        ],
        en: [
          { src:"../audios/claire/CaféGateauParfumLingerie-anglaisavecaccentfrançais-Pub.mp3", title: "Café Gateau Parfum Lingerie - anglais avec accent français - Pub" },

        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Thibault Rispal (DestroKhorne)",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "thibault",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/thibault/A_chaud-Sliders-chapitre_03.mp3", title: "A chaud - Sliders chapitre 03 - Livre Audio" },
          { src:"../audios/thibault/231Germaine-OHara.mp3", title: "Ohara - 231Germaine - Fiction Audio" },
          { src:"../audios/thibault/1287-Mamie petouse.mp3", title: "Mamie petouse - 1287 - Fiction Audio" },
          { src:"../audios/thibault/La_Descente_Rick_Hensen.mp3", title: "Rick Hensen - La Descente - Fiction Audio" },
          { src:"../audios/thibault/Le-Chasseur-Orion.mp3", title: "Le Chasseur - Orion - Fiction Audio" },
          { src:"../audios/thibault/CINQ-Martin.mp3", title: "Martin - CINQ - Fiction Audio" },
          { src:"../audios/thibault/Les résidents et vils pouilleux - Franklin et le portier.mp3", title: "Franklin & Le portier - Les Résidents et vils pouilleux - Fiction Audio" }
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [
      ],
    },
    {
      nomPrenom: "Valéry Stasser",
      posteFr: "Comédien",
      posteEn: "Actor",
      image: "valery",
      flag: ["fr"],
      audios: {
        fr: [
          { src:"../audios/valery/LamortduLoup-AlfreddeVigny-ThalieEnvolee.mp3", title: "La mort du Loup - Alfred de Vigny - Thalie Envolée" },
          { src:"../audios/valery/Monrevefamilier-PaulVerlaine-ThalieEnvolee.mp3", title: "Mon rêve familier - Paul Verlaine - Thalie Envolée" },
          { src:"../audios/valery/Fantaisie-GerarddeNerval-ThalieEnvolee(HD).mp3", title: "Fantaisie - Gerard de Nerval - Thalie Envolée" },
          { src:"../audios/valery/dortmunder.mp3", title: "Dortmunder" },
        ],
        en: [
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },
    {
      nomPrenom: "Aubrey Tyson",
      posteFr: "Comédienne",
      posteEn: "Actress",
      image: "aubrey",
      flag: ["en"],
      audios: {
        fr: [
        ],
        en: [
          { src:"../audios/aubrey/AubreyTyson-CorporateNarrationV1.mp3", title: "Corporate Narration V1" },
          { src:"../audios/aubrey/Caring&Warm-Jessica-PSACommercial.mp3", title: "Caring & Warm - Jessica - PSA Commercial" },
          { src:"../audios/aubrey/CasualandCool-TheRealReal-Commercial.mp3", title: "Casual and Cool - The Real Real - Commercial" },
          { src:"../audios/aubrey/ConversationalAdult-DollarBank-Commercial.mp3", title: "Conversational Adult - Dollar Bank - Commercial" },
          { src:"../audios/aubrey/Friendly&Welcoming-ShredNations-OnHoldMessage.mp3", title: "Friendly & Welcoming - Shred Nations - On Hold Message" },
          { src:"../audios/aubrey/Fun&Lighthearted-MercedesBenzBarkAssist-Commercial.mp3", title: "Fun & Lighthearted - Mercedes Benz Bark Assist - Commercial" },
          { src:"../audios/aubrey/MillennialMom-Telluride-Commercial.mp3", title: "Millennial Mom - Telluride - Commercial" },
          { src:"../audios/aubrey/Narrator-FamilyServices-Commercial.mp3", title: "Narrator - Family Services - Commercial" },
          { src:"../audios/aubrey/Narrator-FamilyServices-IVR.mp3", title: "Narrator - Family Services - IVR" },
          { src:"../audios/aubrey/Narrator-iFlexProject-Explainer.mp3", title: "Narrator - iFlex Project - Explainer" },
          { src:"../audios/aubrey/Narrator-MeetRandy-Presentation.mp3", title: "Narrator - Meet Randy - Presentation" },
          { src:"../audios/aubrey/Narrator-NewMobileApp-Explainer.mp3", title: "Narrator - New Mobile App - Explainer" },
          { src:"../audios/aubrey/Narrator-OVPathForward-WebVideo.mp3", title: "Narrator - OV's Path Forward - Web Video" },
        ],
        jp: []
      },
      iconsFr: [],
      iconsEn: [],
    },


    {
      nomPrenom: "Alice Bracco",
      posteFr: "Community Manager",
      posteEn: "Community Manager",
      image: "alice",
      flag: ["fr"],
      audios: {
        fr: [],
        en: [],
        jp: [],
      },
  
      iconsFr: [
        {
          icon: <Instagram width={20} height={20} />,
          lien: "https://www.instagram.com/comalice.cm?igsh=ZWhhaGF2ZHV5ZGV2",
        },
      ],
    },
    {
      nomPrenom: "Basile Garoufalakis (BAZ)",
      posteFr: "Graphiste Illustrateur",
      posteEn: "Graphic designer & illustrator",
      image: "basile",
      flag: ["fr"],
      audios: {
        fr: [],
        en: [],
        jp: [],
      },  
      iconsFr: [
        {
          icon: <Instagram width={20} height={20} />,
          lien: "https://www.instagram.com/bazgraphite/",
        },
      ],
    },
    {
      nomPrenom: "Romanne Aguenier-Pailler",
      posteFr: "Rédactrice & Correctrice",
      posteEn: "Copywriter",
      image: "romanne",
      flag: ["fr"],
      audios: {
        fr: [],
        en: [],
        jp: [],
      },  
      iconsFr: [
        {
          icon: <Linkedin width={20} height={20} />,
          lien: "https://www.linkedin.com/in/romanne-pailler-1b35092bb/",
        },
      ],
    },
  ],
  pricingCard: [
    {
      titre: "IVR / SVI",
      texte:
        "Bénéficiez de messages vocaux sur mesure, enregistrés par des comédiens professionnels pour guider et informer efficacement vos clients et vos contacts grâce à nos voix pour votre serveur vocal interactif.",
      price: 100,
    },
    {
      titre: "Livre audio",
      texte:
        "Transformez vos écrits en expériences auditives immersives. Nos voix professionnelles narrent vos histoires, offrant à votre audience une nouvelle manière de se connecter à votre contenu.",
      price: 350,
    },
    {
      titre: "Spot publicitaire",
      texte:
        "Captivez votre audience dès les premières secondes avec un spot publicitaire impactant. Nos talents vocaux professionnels donnent vie à votre message, garantissant un impact maximal auprès de vos cibles.",
      price: 500,
    },
    {
      titre: "Fiction Audio (Saga Audio)",
      texte:
        "Plongez vos auditeurs dans des mondes captivants avec nos sagas audio haut de gamme. Nous créons des univers sonores riches et des narrations dynamiques, interprétées par des comédiens de talent, pour une expérience d'écoute inoubliable.",
      price: 5000,
    },
    {
      titre: "Audio de relaxation",
      texte:
        "Nous créons des enregistrements audio relaxants pour favoriser la détente et le bien-être, adaptés à divers environnements tels que les hôpitaux, les spas et les centres de bien-être, ou simplement pour un usage personnel.",
      price: 2500,
      info: "-20% pour les établissements publics",
    },
  ],
  reviews: [
    {
      nomPrenom: "Raoul Miclo",
      poste: "Lead Animator",
      entreprise: "PLAYSTUDIO",
      langKey: "raoul",
      review:
        "Ricardo, connu sous le nom d'Akira, est un acteur talentueux capable de jouer divers rôles avec aisance. Il est également à l'origine d'une plateforme de diffusion majeure pour la série et excelle en tant que responsable commercial, maîtrisant le développement et la communication de projets marketing. Je le recommande vivement à toute personne.",
      image: "raoul",
      stars: {
        count: 4,
        max: 5,
      },
    },
    {
      nomPrenom: "Matthieu Papazoglakis",
      langKey: "matthieu",
      review:
        "Le studio S.A.C. est le coup de pouce qui m'a lancé. C'était l'opportunité de travailler avec des pros dans des conditions raisonnables, faire des rencontres inoubliables et surtout passer d'excellents moments tout en restant à échelle humaine : On trouve ensemble les solutions aux problèmes qui peuvent survenir tout en restant dans un cadre super convivial !",
      image: "mathieu",
      stars: {
        count: 5,
        max: 5,
      },
    },
    {
      nomPrenom: "Grégory de Leew",
      poste: "Photographe et vidéaste",
      entreprise: "free-lance",
      langKey: "gregory",
      review:
        "Plusieurs années que nous travaillons ensemble, notamment sur le projet de chaine Youtube. J'ai donc un avis très positif sur la qualité et le rendu global pour S.A.C studio. Faites leurs confiance les yeux fermés pour vos projets, vous serez parfaitement guidé.",
      image: "gregory",
      stars: {
        count: 5,
        max: 5,
      },
    },
  ],
};


export { config };
